<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="800px"
    scrollable
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        {{ editedContact.name }} {{ $t('clients.general.change_history_lower') }}
      </v-card-title>

      <v-card-text class="px-0">
        <v-progress-circular
          v-if="loading[`get:api/clients/history/${$route.params.contactId}`]"
          color="primary"
          class="mx-4"
          indeterminate
        />

        <h3 v-else-if="historyEntries.length === 0" class="text-h5 mx-4">
          {{ $t('clients.general.no_data') }}
        </h3>

        <v-expansion-panels v-else hover flat>
          <v-expansion-panel v-for="(entry, entryIndex) of historyEntries" :key="entryIndex">
            <v-expansion-panel-header>
              <span>{{ entry.user ? `${entry.user.personal_data.full_name}.` : '' }}</span>
              <span>{{ format(new Date(entry.created_at), 'yyyy-MM-dd') }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="entry.items"
                :mobile-breakpoint="0"
                disable-pagination
                disable-sort
                hide-default-footer
              >
                <template v-slot:item="props">
                  <tr>
                    <td>{{ $t(`clients.model_fields.${props.item.field}`) }}</td>
                    <td>{{ props.item.oldValue }}</td>
                    <td>{{ props.item.newValue }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.native="close">
          {{ $t('general.controls.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import contactService from '@/api/contact-service';
import { mapGetters, mapState } from 'vuex';
import store from '@/store';
import { format } from 'date-fns';

export default {
  name: 'ContactHistoryDialog',

  data() {
    return {
      historyEntries: [],
    };
  },

  computed: {
    ...mapState('contacts', ['editedContact']),
    ...mapGetters(['loading']),

    headers() {
      return [
        { text: this.$t('clients.table_fields.field') },
        { text: this.$t('clients.table_fields.old_value') },
        { text: this.$t('clients.table_fields.new_value') },
      ];
    },
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('contacts/viewContact', +to.params.contactId)
      .then(() => {
        next();
      })
      .catch(() => {
        next(to.meta.getBackRoute());
      });
  },

  created() {
    this.getClientHistory();
  },

  methods: {
    format,

    async getClientHistory() {
      const { data } = await contactService.getHistory(+this.$route.params.contactId);

      for (let i = 0; i < data.length; i++) {
        const entry = data[i];
        entry.items = [];

        Object.keys(entry.old_values).forEach((key) => {
          entry.items.push({
            field: key,
            oldValue: entry.old_values[key],
            newValue: entry.new_values[key],
          });
        });
      }

      this.historyEntries = data;
    },

    close() {
      this.$router.push(this.$route.meta.getBackRoute());
    },
  },
};
</script>
