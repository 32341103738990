<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
  >
    <ContactForm
      :disabled="$store.getters.loading[`put:api/clients/${editedContact.id}`]"
      :errors="contactValidationErrors"
      :contact="editedContact"
      @clear:errors="CLEAR_CONTACT_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '@/store';
import ContactForm from '@/components/forms/ContactForm';

export default {
  name: 'EditContact',

  components: { ContactForm },

  computed: {
    ...mapState('contacts', ['editedContact', 'contactValidationErrors', 'contactFilterParams']),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('contacts/editContact', +to.params.contactId)
      .then(() => {
        next();
      })
      .catch(() => {
        next(to.meta.getBackRoute());
      });
  },

  methods: {
    ...mapActions('contacts', ['updateContact']),
    ...mapMutations('contacts', ['CLEAR_CONTACT_VALIDATION_ERRORS']),

    onSave(contact) {
      this.updateContact(contact).then(() => {
        this.goBack();
      });
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute());
    },
  },
};
</script>
